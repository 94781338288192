/**
 * @name: 交易管理-待发货接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-待发货接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISaleNoSendParam} from "@/apis/sale/no-send/types";

/**
 * 待发货分页查询
 * @param param
 */
export const orderNoSendQueryApi = (param: ISaleNoSendParam) => get("/card/back/order/queryByPage", param)
/**
 * 批量发货
 * @param data
 */
export const orderBatchSendGoodsAPi = (data: any) => postJ(`/card/back/order/batchSendGoods?orderIds=${data.orderIds.join(',')}`, data)
/**
 * 订单商品发货信息
 * @param id
 */
export const orderQueryOrderProductInfoApi = (id: string) => get("/card/back/order/queryOrderProductInfo", {id})
/**
 * 拆包裹发货
 * @param id
 */
export const orderQuerySendGoodsInfoApi = (id: string) => get("/card/back/order/querySendGoodsInfo", {id})
/**
 * 拆包裹发货
 * @param data
 */
export const orderUnpackSendGoodsApi = (data: any) => postJ("/card/back/order/unpackSendGoods", data)
