
/**
 * @name: 交易管理-待发货
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-待发货
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {
  orderBatchSendGoodsAPi,
  orderNoSendQueryApi,
  orderQueryOrderProductInfoApi,
  orderQuerySendGoodsInfoApi, orderUnpackSendGoodsApi
} from "@/apis/sale/no-send"
import { ISaleNoSendParam } from "@/apis/sale/no-send/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, getlogisticStatus} from "@/utils/common";
import {
  expressCompanyCodeQueryByListApi,
  orderQueryLogisticsDetailApi,
  orderQuerySendGoodsDetailApi
} from "@/apis/sale/order";

@Component({})
export default class saleNoSend extends Vue {
  getlogisticStatus = getlogisticStatus
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleNoSendParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '',
        type: 'selection',
        width: 50,
        align: "center"
      },
      {
        label: '订单编号',
        prop: "orderSn",
        align: "center",
        width: 180,
        search: true
      },
      {
        label: '用户',
        prop: "userKeywords",
        search: true,
        hide: true,
        placeholder: '用户名/手机号'
      },
      {
        label: '用户名',
        prop: "userName",
        align: "center"
      },
      {
        label: '手机号',
        prop: "userPhone",
        align: "center"
      },
      {
        label: '商品数量',
        prop: "productNumber",
        align: "center",
        slot: true
      },
      {
        label: '收货地址',
        prop: "detailAddress",
        align: "center",
        slot: true,
        overHidden: true
      },
      {
        label: '下单时间',
        prop: "orderTime",
        align: "center",
        search: true,
        type: "daterange"
      }
    ]
  }

  selectRows: any = []

  tempAllSendProduct: any = []
  // 全部发货弹窗
  allSendProductDialog = false
  allSendProductForm: any = {
    logisticCode: '',
    shipperCode: ''
  }

  /**
   * 快递公司列表
   */
  companyList: any = []

  // 订单商品发货弹窗
  orderSendDetailDialog = false
  orderSendDetailObj: any = {}

  selectOrderRow: any = {}

  // 物流详情弹窗
  logisticsDialog = false
  // 物流信息
  logisticsInfo: any = {}

  // 发货明细
  sendDetail = false
  // 发货明细数据
  sendDetailArr: any = []

  // 补发货弹窗
  retrySendProductDialog = false
  // 补发货数据
  retrySendProductObj: any = {}

  retrySendProductForm: any = {
    logisticCode: '',
    shipperCode: ''
  }

  searchLoading = false

  async remoteMethod (query: string) {
    this.searchLoading = true
    await this.getCompanyList(query)
    this.searchLoading = false
  }


  getCompanyList (keyword?: string) {
    return new Promise(resolve => {
      expressCompanyCodeQueryByListApi(keyword || '').then(e => {
        this.companyList = e.slice(0, 100);
        resolve(null)
      })
    })
  }

  openOrderSendDetailDialog (row: any) {
    this.selectOrderRow = row;
    orderQueryOrderProductInfoApi(row.orderId).then(e => {
      this.orderSendDetailObj = e;
      this.orderSendDetailDialog = true
    })
  }

  getList () {
    this.tableLoading = true

    const query: ISaleNoSendParam = deepCopy(this.queryParam)
    if (query.orderTime && query.orderTime.length) {
      query.orderTimeStart = query.orderTime[0]
      query.orderTimeEnd = query.orderTime[1]
    } else {
      query.orderTimeStart = ''
      query.orderTimeEnd = ''
    }
    delete query.orderTime

    orderNoSendQueryApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  checkA9 (rule: any, value: any, callback: Function) {
    if (!/^[A-Za-z0-9]+$/.test(value)) {
      return callback(new Error('只支持字母、数字'))
    }
    callback()
  }

  batchSendProduct () {
    if (!this.selectRows || !this.selectRows.length) {
      return this.$message.error("请选择要发货的订单")
    }
    if (this.selectRows.length > 1) {
      const first = this.selectRows[0]
      const addr = `${first.province}${first.city}${first.area}${first.detailAddress}`
      for (let i = 1; i < this.selectRows.length; i++) {
        const item = this.selectRows[i]
        if (`${item.province}${item.city}${item.area}${item.detailAddress}` != addr) {
          return this.$message.error("收货地址不同，不允许批量发货！")
        }
      }
    }
    this.tempAllSendProduct = deepCopy(this.selectRows)
    this.allSendProductDialog = true
  }

  handleOpenAllSendProduct (row: any) {
    this.tempAllSendProduct = [row];
    this.allSendProductDialog = true
  }

  handleAllSendProduct (done: Function, loading: Function) {
    // @ts-ignore
    this.$refs.allSendProductRef.validate(valid => {
      if (valid) {
        orderBatchSendGoodsAPi({
          logisticCode: this.allSendProductForm.logisticCode,
          shipperCode: this.allSendProductForm.shipperCode,
          orderIds: this.tempAllSendProduct.map((item: any) => item.orderId)
        }).then(e => {
          if (e) {
            this.$message.success("操作成功!")
            this.getList()
            done()
          }
        }).finally(() => {
          loading()
        })
      } else {
        loading()
      }
    })
  }

  handleAllSendProductClose () {
    this.allSendProductForm = {
      logisticCode: '',
      shipperCode: ''
    }
  }

  openLogisticsDialog (row: any) {
    this.logisticsInfo.logisticName = row.logisticName
    this.logisticsInfo.logisticCode = row.logisticCode
    orderQueryLogisticsDetailApi(row.orderDeliveryId).then(e => {
      this.logisticsInfo.list = e || []
      this.logisticsDialog = true
    })
  }

  openSendDetail (orderDeliveryId: string) {
    orderQuerySendGoodsDetailApi(orderDeliveryId).then(e => {
      this.sendDetailArr = e;
      this.sendDetail = true
    })
  }

  openRetrySendProductDialog (id: string) {
    orderQuerySendGoodsInfoApi(id).then(e => {
      this.retrySendProductObj = e;
      this.retrySendProductDialog = true
    })
  }

  async retrySendProductEnter (done: Function, loading: Function) {
    if (this.retrySendProductObj && this.retrySendProductObj.orderProductVoList) {
      for (let i = 0; i < this.retrySendProductObj.orderProductVoList.length; i++) {
        const item = this.retrySendProductObj.orderProductVoList[i]
        if (item.retryQty && parseFloat(item.retryQty || 0) != 0) {
          // 正则整数校验retryQty
          const reg = /^[1-9]\d*$/
          if (!reg.test(item.retryQty)) {
            loading()
            return this.$message.error("请输入正整数的发货数量")
          }
          if (parseInt(item.retryQty) > parseInt(item.payQty)) {
            loading()
            return this.$message.error("发货数量不能超出商品数量")
          }
        }
      }
      const arr = this.retrySendProductObj.orderProductVoList.filter((item: any) => item.retryQty && parseFloat(item.retryQty || 0) != 0)
      if (!arr || !arr.length) {
        loading()
        return this.$message.error("未填写发货数量!")
      }
      // @ts-ignore
      this.$refs.retrySendProductRef.validate(valid => {
        if (valid) {
          // @ts-ignore
          orderUnpackSendGoodsApi({
            orderId: this.retrySendProductObj.orderId,
            logisticCode: this.retrySendProductForm.logisticCode,
            shipperCode: this.retrySendProductForm.shipperCode,
            orderDeliveryDtlDtoList: arr.map((item: any) => {
              return {
                orderProductId: item.orderProductId,
                deliveryQty: item.retryQty
              }
            })
          }).then(e => {
            if (e) {
              this.$message.success("操作成功!")
              this.getList()
              done()
            }
          }).finally(() => {
            loading()
          })
        } else {
          loading()
        }
      })
    } else {
      loading()
      this.$message.error("该订单暂不允许发货!")
    }
  }

  retrySendProductClose () {
    this.retrySendProductForm = {
      logisticCode: '',
      shipperCode: ''
    }
  }

  created () {
    this.getCompanyList()
    this.getList()
  }
}
